import React from 'react';
import { Box, Typography } from '@mui/material';
import statsImage from '../../assets/stats.png';

const Stats = () => {
  return (
    <><Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
      Redefining Automotive Recruitment for Modern Organizations
      </Typography>
      <Typography variant="h6" sx={{ mb: 4 }}>
      Seamlessly connecting automotive manufacturers, suppliers, and tech companies with the brightest minds in the industry.</Typography>
    </Box><Box
      sx={{
        mt: 0.2,
        fontFamily: 'monospace',
        height: '50vh', // Half the viewport height
        display: 'flex', // Use flexbox for layout
        justifyContent: 'space-around', // Space items evenly
        alignItems: 'center', // Center items vertically
        textAlign: 'center', // Center the text inside
        backgroundImage: `url(${statsImage})`, // Use imported background image
        backgroundSize: 'cover', // Ensure background image covers the entire container
        backgroundPosition: 'center', // Center the background image
        color: '#fff', // White text color for contrast
        px: 2, // Horizontal padding for small screens
        flexDirection: { xs: 'column', sm: 'row' }, // Stack items on small screens, row on larger
      }}
    >
        <Typography
          variant="h3"
          sx={{
            mb: { xs: 2, sm: 0 }, // Add bottom margin for stacked layout
            fontWeight: 600,
            fontSize: { xs: '2rem', md: '3rem' }, // Adjust font size for responsiveness
          }}
        >
          160+ <br />
          <Typography component="span" variant="h6" sx={{ fontSize: { xs: '1rem', md: '1.2rem' } }}>
          Expert Recruiters
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            mb: { xs: 2, sm: 0 }, // Add bottom margin for stacked layout
            fontWeight: 600,
            fontSize: { xs: '2rem', md: '3rem' }, // Adjust font size for responsiveness
          }}
        >
          200+ <br />
          <Typography component="span" variant="h6" sx={{ fontSize: { xs: '1rem', md: '1.2rem' } }}>
            Automotive Clients
          </Typography>
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            fontSize: { xs: '2rem', md: '3rem' }, // Adjust font size for responsiveness
          }}
        >
          40+ <br />
          <Typography component="span" variant="h6" sx={{ fontSize: { xs: '1rem', md: '1.2rem' } }}>
          Automotive Talent Acquisition Managers
          </Typography>
        </Typography>
      </Box></>
  );
};

export default Stats;
